const Constants = {
  RULE_TYPE_TEXT_IGNORE : 1,
  RULE_TYPE_TEXT_REPLACE : 2,
  RULE_TYPE_CONTENT_EXCLUSION : 3,
  RULE_TYPE_URL_EXCLUSION : 4,
  RULE_TYPE_IGNORE_INLINE : 5,
};

export default Constants;

export const DATE_FORMAT = 'dd MMMM yyyy';

export const REGEX_LINK = (languages) => `/^(http|https)://(?:(www\.)?(?!.*/(${languages.join('|')})/)(?=[^\\s]*\\.(?!(html|php))[a-zA-Z0-9]+$)|[^\\s]+\/.*)$/`;

export const PLAN_DETAILS_KEYS = [
  'new_languages',
  'one_month_trial',
  'multi_content_indexed',
  'translate_new_content',
  'free_installation',
  'unlimited_languages',
];

export const FREQUENT_QUESTIONS_KEYS = [
  'trial',
  'seo',
  'quota',
];
